(function () {
    'use strict';

    angular.module("aerosApp")
        .factory('HtmlHelperService', HtmlHelperService);

    HtmlHelperService.$inject = ['$sce'];

    function HtmlHelperService($sce) {
        return {
            convertNewLines: convertNewLines
        };

        function convertNewLines(str) {
            return $sce.trustAsHtml(str.replace('\n', '<br />'));
        }
    }
}());
